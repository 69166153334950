import { Col, Row } from 'antd';
import React from 'react';
import * as texts from 'assets/texts/program';
import QuoteForm from './Form';
import styles from './Quote.module.scss';

const Quote = () => {
  return (
    <Row className={styles.Container}>
      <Col span={24}>
        <Row className={styles.TitleContainer}>
          <Col span={24}>
            <h2 className={styles.Title}>{texts.quoteTitle}</h2>
          </Col>
        </Row>
        <Row justify="space-between" gutter={{ sm: 40 }}>
          <Col xs={0} sm={12}>
            <img className={styles.Image} src={texts.quoteImage} alt="Quote program" />
          </Col>
          <Col xs={24} sm={12}>
            <QuoteForm />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Quote;
