import React, { FC, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { ThingToDo } from 'apollo/generated/types';
import { Col, Row } from 'antd';
import useMaxBreakpoint from 'utils/styles/useMaxBreakpoint';
import { Breakpoints } from 'utils/styles/breakpoints';
import styles from './ActivityDetails.module.scss';

interface Props {
  thingToDo: ThingToDo;
}

const ThingsToDoDetails: FC<Props> = ({ thingToDo }: Props) => {
  const [imagesToShow, setImagesToShow] = useState<string[]>([]);
  const currentBreakpoint = useMaxBreakpoint();
  const isMobile = currentBreakpoint <= Breakpoints.XS;
  const isTablet = currentBreakpoint <= Breakpoints.MD;

  const backgroundColorClassName: Record<string, string> = {
    Picnic: styles.YellowContainer,
    Aves: styles.YellowContainer,
    'Kayaks y botes': styles.BlueContainer,
    'Pump track': styles.BrownContainer,
    Flora: styles.GreenContainer,
    'Stand-up paddle': styles.BlueContainer,
    Senderismo: styles.BrownContainer,
    'Circuito MTB': styles.BrownContainer,
    Balsa: styles.BlueContainer,
    Fotografía: styles.YellowContainer,
    'Muro de escalada': styles.BrownContainer,
    Bowlpark: styles.BrownContainer,
  };

  const iconBackgroundColorClassName: Record<string, string> = {
    Picnic: styles.YellowIcon,
    Aves: styles.YellowIcon,
    'Kayaks y botes': styles.BlueIcon,
    'Pump track': styles.BrownIcon,
    Flora: styles.GreenIcon,
    'Stand-up paddle': styles.BlueIcon,
    Senderismo: styles.BrownIcon,
    'Circuito MTB': styles.BrownIcon,
    Balsa: styles.BlueIcon,
    Fotografía: styles.YellowIcon,
    'Muro de escalada': styles.BrownIcon,
    Bowlpark: styles.BrownIcon,
  };

  const nameHasBackgroundColor = Object.keys(backgroundColorClassName).includes(
    thingToDo.name
  );

  const nameHasIconBackgroundColor = Object.keys(iconBackgroundColorClassName).includes(
    thingToDo.name
  );

  const getImagesToShow = () => {
    let thingsToDoImages = thingToDo.thingToDoImages || [];

    if (!thingToDo.thingToDoImages) {
      thingsToDoImages = [];
    } else if (thingToDo.thingToDoImages.length === 1) {
      thingsToDoImages = thingToDo.thingToDoImages;
    } else if (isMobile) {
      thingsToDoImages = thingToDo.thingToDoImages.slice(1, 2);
    } else if (isTablet) {
      thingsToDoImages =
        thingToDo.thingToDoImages.length > 2
          ? thingToDo.thingToDoImages.slice(1, 3)
          : thingToDo.thingToDoImages.slice(0, 2);
    } else if (thingToDo.thingToDoImages.length < 4) {
      thingsToDoImages = thingToDo.thingToDoImages;
    } else {
      thingsToDoImages = thingToDo.thingToDoImages.slice(1, 4);
    }

    setImagesToShow(thingsToDoImages.map((thingToDoImage) => thingToDoImage.image || ''));
  };

  const getImagesSpan = () => {
    if (isMobile) {
      return 24;
    }

    return imagesToShow.length ? 24 / imagesToShow.length : 24;
  };

  useEffect(() => {
    getImagesToShow();
  }, [thingToDo]);

  return (
    <Row
      className={
        nameHasBackgroundColor
          ? backgroundColorClassName[thingToDo.name]
          : styles.GreenContainer
      }
      align="middle"
      justify="space-around"
    >
      <Col xs={24} sm={8}>
        <Row justify="start" align="middle">
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: thingToDo.icon || '' }}
            className={
              nameHasIconBackgroundColor
                ? iconBackgroundColorClassName[thingToDo.name]
                : styles.GreenIcon
            }
          />
          <h2 className={styles.Title}>{thingToDo.name}</h2>
        </Row>
        <p className={styles.Description}>{parse(thingToDo.description || '')}</p>
      </Col>
      <Col className={styles.ImagesContainer} xs={24} offset={isMobile ? 0 : 1} sm={15}>
        <Row justify="start" gutter={[10, 0]}>
          {imagesToShow.map((thingToDoImage, index) => (
            <Col
              // eslint-disable-next-line react/no-array-index-key
              key={`${thingToDoImage}-${index}`}
              className={styles.ImageContainer}
              span={getImagesSpan()}
            >
              <img
                className={styles.ActivityImage}
                alt={thingToDo.name || ''}
                src={thingToDoImage}
              />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default ThingsToDoDetails;
