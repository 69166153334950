/* eslint-disable react/no-danger */
import { Carousel, Col, Row } from 'antd';
import React, { FC } from 'react';
import ScrollableItem from 'components/sharedComponents/ScrollableItem';
import * as texts from 'assets/texts/program';
import styles from './Description.module.scss';

export interface DescriptionProps {
  title: string;
  description: string;
  duration: number;
  targetGroup: string;
  programPictureUrls: string[];
}

const MAXIMUM_IMAGES_TO_SHOW = 5;
const MINIMUM_IMAGES_TO_SHOW = 2;

const Description: FC<DescriptionProps> = ({
  title,
  description,
  duration,
  targetGroup,
  programPictureUrls,
}: DescriptionProps) => {
  const { innerWidth: width } = window;

  const getImagesToShow = () => {
    if (programPictureUrls.length < MAXIMUM_IMAGES_TO_SHOW) {
      return programPictureUrls.length;
    }

    if (width > 768) {
      return MAXIMUM_IMAGES_TO_SHOW;
    }

    return MINIMUM_IMAGES_TO_SHOW;
  };

  return (
    <Row>
      <Col span={24}>
        <Row className={styles.Container} justify="center">
          <Col xs={24} sm={16}>
            <h2 className={styles.Title}>{title}</h2>
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={10} sm={6} className={styles.InformationContainer}>
            <h6>{texts.estimatedDuration}</h6>
            <h3>
              {duration} {texts.hours}
            </h3>
          </Col>
          <Col xs={11} sm={6} offset={1} className={styles.InformationContainer}>
            <h6>{texts.age}</h6>
            <h3>{targetGroup}</h3>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <div className={styles.ScrollableList}>
          <Carousel
            dots={false}
            slidesToShow={getImagesToShow()}
            draggable
            swipeToSlide
            touchThreshold={50}
            focusOnSelect
            autoplay
          >
            {programPictureUrls.map((imageUrl, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`div-${index}`}>
                  <ScrollableItem
                    index={index}
                    imageUrl={imageUrl}
                    scrollableImageClassName={styles.ScrollableImage}
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
      </Col>
    </Row>
  );
};

export default Description;
