import penIcon from 'assets/images/pen.svg';
import worldIcon from 'assets/images/world.svg';
import suitcaseIcon from 'assets/images/suitcase.svg';
import misionImg from 'assets/images/mision.png';
import visionImg from 'assets/images/vision.png';

export const title = 'QUINES SOMOS';
export const tricao = 'Fundación Parque Tricao';
export const description =
  'Fundación Parque Tricao es una organización sin fines de lucro, que promueve la creación de espacios naturales y la biodiversidad, el deporte y la cultura al aire libre, con la finalidad de preservar para las generaciones futuras este espacio natural.';

export const mission = 'Misión';
export const missionDescription =
  'Estamos abocados al rescate y preservación de la flora y fauna autóctona de nuestra región a través de la restauración de un espacio natural, fomentando una educación integral con la naturaleza como eje central para el desarrollo de actividades culturales y recreativas.';
export const vision = 'Visión';
export const visionDescription =
  'Ser un espacio de conservación a disposición de la comunidad con foco en el aprendizaje, el deporte, la vida sana y el respeto hacia el entorno y la naturaleza. Nos mueve generar conciencia en niños y jóvenes sobre la relevancia de los recursos naturales, animales y actividad física.';

export const mainMessage =
  '<i>“<b>Fundación Parque Tricao es una organización sin fines de lucro</b>, que promueve la creación de espacios naturales y la biodiversidad, el deporte y la cultura al aire libre, con la finalidad de preservar para las generaciones futuras este espacio natural”.</i>';

export const directorWordsTitle = 'Palabras del director';

export const directorWordsText =
  'Nuestra principal misión es el cuidado del medioambiente. Estamos abocados al rescate y preservación del bosque nativo y de la vegetación autóctona propia de las quebradas de la zona central de Chile.<br><br>' +
  'A lo largo de los años la naturaleza nos ha enseñado que entregando los cuidados necesarios ella responde de buena manera a los esfuerzos de conservación y rescate.<br><br>' +
  'Así, a través de los múltiples espacios y experiencias que ofrecemos, buscamos fomentar una educación integral, siendo la naturaleza el eje central para el desarrollo de actividades culturales y recreativas.<br><br>' +
  'Bienvenidos a Parque Tricao.';

export const directorWordsName = 'Nicolás Vicuña';

export const directorWordsCharge = 'Director ejecutivo';

export const squareInformation = [
  {
    title: 'Educación',
    icon: penIcon,
    text: 'La Fundación asume un rol activo en promover y gestionar las visitas al parque de diversas redes de organizaciones sociales, como colegios, hospitales, corporaciones municipales, fundaciones y organizaciones sin fines de lucro, provenientes de todo Chile. Parque Tricao es un espacio al aire libre rico en naturaleza, actividades deportivas y cultura que buscamos promover, junto con inculcar un desarrollo integral y sustentable en torno al aire libre, la flora y la fauna.',
  },
  {
    title: 'Conservación',
    icon: worldIcon,
    text: 'La Fundación adquiere un compromiso de preservación de este espacio de naturaleza, único por la biodiversidad de flora y fauna contenida en este lugar privilegiado. El parque tiene una capacidad máxima diaria de visitantes, con el fin de mantener el equilibrio del ecosistema, siempre privilegiando el bienestar de las diversas especies que habitan en él. ',
  },
  {
    title: 'Turismo',
    icon: suitcaseIcon,
    text: 'Parque Tricao es un espacio pensado para toda la familia, donde adultos, jóvenes y niños pueden disfrutar de un día completo en torno a la naturaleza, la cultura y el deporte. El parque es un aporte a la comuna y a la región, potenciando el turismo y el crecimiento económico de la zona. Buscamos compartir experiencias de contacto con la naturaleza, deportivas y culturales, para toda la comunidad.',
  },
];

export const bussinessValues = [
  {
    title: 'Misión',
    image: misionImg,
    text: '<b>Estamos abocados al rescate y preservación de la flora y fauna autóctona de nuestra región</b> a través de la restauración de un espacio natural, fomentando una educación integral con la naturaleza como eje central para el desarrollo de actividades culturales y recreativas.',
  },
  {
    title: 'Visión',
    image: visionImg,
    text: '<b>Ser un espacio de conservación a disposición de la comunidad</b> con foco en el aprendizaje, el deporte, la vida  sana y el respeto hacia el entorno y la naturaleza. Nos mueve generar conciencia en niños y jóvenes sobre la relevancia de los recursos naturales, animales y actividad física.',
  },
];

export const historyTitle = 'Nuestra historia';

export const historyText =
  'Parque Tricao es un lugar que está en constante crecimiento, donde cada uno de sus hitos, lugares y experiencias han tenido una especial dedicación y esfuerzo. Acá, podrás conocer cómo se inició este viaje y todo el proceso que hay detrás.';

export const yearsDescriptions = [
  {
    year: '2020',
    description: {
      title: 'Jardín Botánico',
      text: 'Fue en 2019 cuando se dio inicio a este ambicioso proyecto, que buscaba darle un espacio a miles de plantas y flores. Este no es solo un lugar para aprender sobre la flora nativa, sino donde se inicia nuestra labor de reforestación.',
    },

    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/our-history/botanic-garden.jpg',
  },
  {
    year: '2019',
    description: {
      title: 'Apertura del  parque al público general',
      text: 'Una nueva etapa empieza en Parque Tricao. Un legado de la naturaleza se abre a toda persona que quiera disfrutar, conocer, aprender y respetar un parque que hoy es un oasis en el secano costero de la Región de Valparaíso. Poder entregar sus experiencias y biodiversidad es un compromiso con la cultura y enseñanza de nuestro país.',
    },

    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/our-history/public-apperture.jpg',
  },
  {
    year: '2018',
    description: {
      title: 'Playa Blanca',
      text: 'Una playa a la orilla del tranque, rodeada de la frescura y aroma del bosque nativo es un paisaje único que se transforma en una pausa dentro de la actividad deportiva del parque. Sus arenas blancas te invitan a contemplar el paisaje del tranque mientras mojas tus pies en su tranquila orilla.',
    },
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/our-history/playa-blanca.jpg',
  },
  {
    year: '2018',
    description: {
      title: 'Jardín nuevo, segunda etapa',
      text: 'Con los años, el Jardín Giverny ya era un paisaje digno de ser pintado por Claude Monet, tal y como retrató los de Francia. Por eso, surge la idea de expandirlo para llenar de flores un nuevo rincón del parque. Los nuevos senderos y puentes que recorren sus aguas, logran así, un recorrido más extenso, lleno de sensaciones y simetrías que llaman a la tranquilidad y el descanso.',
    },
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/our-history/giverny-2.jpg',
  },
  {
    year: '2017',
    description: {
      title: 'Construcción del Aviario',
      text: 'Fue en 2017 cuando después de casi dos años se consolidó el gran aviario de Parque Tricao. Las más de 50 especies de aves, llegaron a Parque Tricao para mostrar y enseñar plumajes y colores increíbles que podemos encontrar en la naturaleza. Más que un aviario, este proyecto se transformó en una aventura que invita a descubrir la riqueza de la flora nativa y la majestuosidad de las aves exóticas.',
    },
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/our-history/aviary.jpg',
  },
  {
    year: '2016',
    description: {
      title: 'Jardín Giverny',
      text: 'Un pedacito de los jardines de Giverny en Francia que retrató Claude Monet, se trasladó a una pequeña quebrada en Parque Tricao. Cada detalle, cada uno de sus colores y formas, se pensaron para evocar los increíbles jardines franceses. Desde un principio se pensó como un lugar de descanso y paseo, donde el ruido del agua cayendo por las cascadas y los paseos por sus puentes flotantes hacen de Giverny un lugar mágico y especial.',
    },
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/our-history/giverny-humedal.jpg',
  },
  {
    year: '2012',
    description: {
      title: 'Construcción de área de picnic',
      text: 'Rápidamente la belleza de Parque Tricao cautivó a la gente del sector. Es por eso que en 2012 se comenzaron a levantar áreas de picnic en sectores perfectos para conectarse con la naturaleza al mismo tiempo en que se almuerza con amigos y familiares. Poco a poco estos espacios fueron consolidándose como lo que hoy son los quinchos.',
    },

    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/our-history/coorporate-events.jpg',
  },
  {
    year: '2011',
    description: {
      title: 'Diseño de senderos y Plaza de la Virgen (primera etapa)',
      text: 'Después de varios años, la restauración de la flora y fauna había dado sus frutos y gracias a eso se diseñaron senderos que recorrieran el parque para que los visitantes pudieran conocer más a fondo las maravillas que hay en las profundidades del bosque de Parque Tricao. Con eso, nació también la primera versión de la Plaza la Virgen, que en ese momento fue un punto de partida para este sorprendente trayecto, pero que hoy se ha transformando en una suerte de mini Tricao, con cada una de sus experiencias reflejadas en miniatura.',
    },

    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/our-history/plaza-la-virgen.jpg',
  },
  {
    year: '2010',
    description: {
      title: 'Construcción del Anfiteatro y Restaurant',
      text: 'La necesidad de fomentar la cultura y crear espacios para la comunidad llevó a la construcción en 2010 de un Anfiteatro para 220 personas donde a lo largo de los años se han presentado músicos, jazzistas y obras de teatro. Este lugar de aprendizaje nace de un compromiso con el arte y la cultura fomentando el talento nacional.\nEn esta misma época se levanta en Parque Tricao el restaurant que si bien siempre ha sido una instancia de reunión y descanso, hoy se transforma también en un centro gastronómico dentro del parque lleno de actividades y sorpresas culinarias.',
    },

    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/our-history/cafeteria.jpg',
  },
  {
    year: '2009',
    description: {
      title: 'Creación del Humedal',
      text: 'El humedal Tricao nace como una medida de mitigación del tranque que incluso obtuvo en 2013 un reconocimiento del Ministerio del Medio Ambiente por el rol que cumple en la restauración de un hábitat dañado. A los pocos meses de su creación, ya se podían ver patos y cisnes nadando por sus aguas, los que encontraron un espacio para garantizar su vida y reproducción. Y para alegría de todos, sólo un año después se encontraron nidos de variadas especies de aves en las orillas del humedal.',
    },

    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/our-history/humedal.jpg',
  },
  {
    year: '2007',
    description: {
      title: 'Creación del Tranque',
      text: 'Este año surgió la idea de crear el embalse Tricao que nació de un pequeño curso de agua que se perdía en el mar. Un año después, se consolidó el tranque de agua azul y cristalina que hoy es el centro del parque. \nEsta iniciativa pasó por todos los procesos de Evaluación de Impacto Ambiental, con el compromiso de mantener el paso de agua para preservar el caudal ecológico del estero. Hoy el tranque es el hábitat de miles de plantas, peces, patos, cisnes y árboles que lo rodean, que conviven en armonía y en las condiciones necesarias para su preservación y desarrollo.',
    },

    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/our-history/tranque.jpg',
  },
];

export const ourTeamTitle = 'Nuestro equipo';

export const ourTeamMembers = [
  {
    name: 'Nicolás Vicuña',
    charge: 'Director ejecutivo',
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_1.jpg',
  },
  {
    name: 'María José Vicuña',
    charge: 'Directora de educación',
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_1.jpg',
  },
  {
    name: 'Cristián Vicuña',
    charge: 'Director de conservación',
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_1.jpg',
  },
  {
    name: 'Fernanda Vicuña',
    charge: 'Directora de turismo',
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_1.jpg',
  },
  {
    name: 'Nicolás Vicuña',
    charge: 'Director ejecutivo',
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_1.jpg',
  },
  {
    name: 'María José Vicuña',
    charge: 'Directora de educación',
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_1.jpg',
  },
  {
    name: 'Cristián Vicuña',
    charge: 'Director de conservación',
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_1.jpg',
  },
  {
    name: 'Fernanda Vicuña',
    charge: 'Directora de turismo',
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_1.jpg',
  },
  {
    name: 'Nicolás Vicuña',
    charge: 'Director ejecutivo',
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_1.jpg',
  },
  {
    name: 'María José Vicuña',
    charge: 'Directora de educación',
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_1.jpg',
  },
  {
    name: 'Cristián Vicuña',
    charge: 'Director de conservación',
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_1.jpg',
  },
  {
    name: 'Fernanda Vicuña',
    charge: 'Directora de turismo',
    imageUrl:
      'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_1.jpg',
  },
];
