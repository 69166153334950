/* eslint-disable react/no-danger */
import React from 'react';
import { Col, Row } from 'antd';
import { Place } from 'apollo/types';
import styles from './GrillButton.module.scss';

interface GrillButtonProps {
  place: Place;
  setSelectedGrill: (place: Place) => void;
}

const GrillButton = ({ place, setSelectedGrill }: GrillButtonProps) => {
  const placeMainImage = place.placeImages && place.placeImages[0].image;
  return (
    <Row className={styles.Container} onClick={() => setSelectedGrill(place)}>
      <div className={styles.Gradient}>
        <Col className={styles.TextContainer} span={24}>
          <Row align="middle" justify="start">
            <Col className={styles.TitleContainer} span={24}>
              <h6 className={styles.Title}>{place.name}</h6>
            </Col>
          </Row>
        </Col>
      </div>
      <Col className={styles.ImageContainer} span={24}>
        <img className={styles.MainImage} src={placeMainImage || ''} alt={place.name} />
      </Col>
    </Row>
  );
};

export default GrillButton;
