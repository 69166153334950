import React, { FC, useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import useGetPackQuery from 'apollo/resolvers/packs/get';
import useGetEntranceTicketTypeQuery from 'apollo/resolvers/entranceTicketTypes/get';
import useGetConfigurationParameters from 'apollo/resolvers/configurationParameters/get';
import calculateSubTotal from 'utils/helpers/calculateSubTotal';
import SelectTicketsCard from 'components/SelectTickets/SelectTicketsCard';
import SelectGrill from 'components/SelectTickets/SelectGrill';
import { Pack, EntranceTicketType } from 'apollo/types';
import * as texts from 'assets/texts/selectTickets';
import PurchaseSteps from 'components/generalUI/PurchaseSteps';
import BottomSummary from 'components/sharedComponents/BottomSummary';
import getDateSearchParam from 'utils/dates/getDateSearchParam';
import { PackOrEntrancePurchase, ActivityPurchase } from 'storage/types';
import { saveData } from 'utils/services/ticketsService';
import { getOrCreatePurchaseData } from 'storage/utils/getOrCreatePurchaseData';
import LoadingModal from 'components/generalUI/LoadingModal';
import Button from 'components/generalUI/Button';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ActualParkAvailability from 'components/sharedComponents/ActualParkAvailability';
import captureEvent from 'utils/post-hog';
import * as events from 'utils/post-hog/events';
import isDateToDisplayIsBefore from 'utils/helpers/isDateToDisplayIsBefore';
import styles from './SelectTickets.module.scss';

const SelectTickets: FC = () => {
  const date = getDateSearchParam();
  const { id, item } = useParams<{ id: string; item: 'packs' | 'entrances' }>();
  const displayDate = isDateToDisplayIsBefore(date, new Date(date));

  const history = useHistory();

  const isThisElementAPack = item === 'packs';
  const data = isThisElementAPack
    ? useGetPackQuery(id, date)
    : useGetEntranceTicketTypeQuery(id, date);
  const { data: configurationData } = useGetConfigurationParameters();

  const purchase = getOrCreatePurchaseData(date);
  const itemInPurchase = purchase[item].findIndex(
    (purchaseItem) => purchaseItem?.id === id
  );

  const [isModalVisible, setIsModalVisible] = useState(true);
  const [selectedItem, setSelectedItem] = useState<Pack | EntranceTicketType>();
  const [subTotal, setSubTotalTickets] = useState<number>(
    itemInPurchase !== -1 ? calculateSubTotal(purchase[item][itemInPurchase]) : 0
  );
  const [lastSubTotal] = useState<number>(subTotal.valueOf());
  const [isBicycle, setIsBicycle] = useState<boolean>();

  const [purchaseData, setPurchaseData] = useState<PackOrEntrancePurchase>(
    itemInPurchase !== -1
      ? purchase[item][itemInPurchase]
      : {
          id: '',
          adultTicketCount: 0,
          otherTicketCount: 0,
          activities: [],
          name: '',
          adultValue: 0,
          otherValue: 0,
        }
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const [grillTotal, setGrillTotal] = useState<number>(purchase.grill?.value || 0);
  const [grillData, setGrillData] = useState<ActivityPurchase | null>(purchase.grill);

  const setIsBicycleFunction = () => {
    if (isThisElementAPack) {
      setIsBicycle(false);
    } else {
      const entranceTicketType = data as EntranceTicketType;
      setIsBicycle(entranceTicketType.isBicycle || false);
    }
  };

  const onButtonSummaryClicked = () => {
    saveData(
      purchase,
      purchaseData,
      grillData,
      grillTotal,
      itemInPurchase,
      purchaseData.adultTicketCount,
      purchaseData.otherTicketCount,
      selectedItem,
      item,
      subTotal,
      lastSubTotal,
      history,
      date,
      texts.warningNoActivityMessage
    );
    captureEvent(events.toPurchaseSummaryButtonClicked);
  };

  useEffect(() => {
    if (data) {
      setIsModalVisible(false);
      setSelectedItem(data);
      setIsBicycleFunction();
    }
  }, [data]);

  return (
    <div className={styles.Container}>
      <LoadingModal visible={isModalVisible} />
      <PurchaseSteps total={4} current={1} />
      <Row justify="center" align="middle">
        <Col xs={22} md={20} xl={16}>
          <Row>
            <Col span={24}>
              <h1 className={styles.Title}>{texts.title}</h1>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <div className={styles.DateButtonCol}>
                <Button onClick={() => history.push('/date')}>
                  <ArrowLeftOutlined />
                  {texts.selectAnotherDate}
                </Button>
              </div>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <h3 className={styles.Date}>{displayDate}</h3>
            </Col>
          </Row>
        </Col>
      </Row>

      {selectedItem && selectedItem.remaining > 0 && selectedItem.status && (
        <>
          <Row justify="center">
            <Col xs={22} md={20} xl={16}>
              <SelectTicketsCard
                selectedItem={selectedItem}
                subTotal={subTotal}
                setSubTotal={setSubTotalTickets}
                purchaseData={purchaseData}
                setPurchaseData={setPurchaseData}
                oversold={configurationData?.getConfigurationParameters.oversold}
              />
            </Col>
          </Row>
          {!isBicycle && (
            <Row justify="center">
              <Col xs={22} md={20} xl={16}>
                <SelectGrill
                  setGrillTotal={setGrillTotal}
                  setGrillData={setGrillData}
                  grillId={grillData ? grillData.id : undefined}
                  title={texts.addGrillTitle}
                />
              </Col>
            </Row>
          )}
        </>
      )}

      <BottomSummary
        total={subTotal + grillTotal}
        totalMessage={texts.subTotal}
        buttonMessage={texts.continueText}
        buttonOnClick={() => onButtonSummaryClicked()}
        isAffix={false}
      />
      <ActualParkAvailability date={date} />
    </div>
  );
};

export default SelectTickets;
