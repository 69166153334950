import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import LoadingModal from 'components/generalUI/LoadingModal';
import useListPacks from 'apollo/resolvers/packs/list';
import * as texts from 'assets/texts/schedulesAndPrices';
import EntranceCard from 'components/SchedulesAndPrices/EntranceCard';
import Button from 'components/generalUI/Button';
import ActualParkAvailability from 'components/sharedComponents/ActualParkAvailability';
import GrillCard from 'components/SchedulesAndPrices/GrillCard';
import useListEntranceTicketTypesQuery from 'apollo/resolvers/entranceTicketTypes/list';
import { EntranceTicketType } from 'apollo/types';
import styles from './SchedulesAndPrices.module.scss';

const SchedulesAndPrices: FC = () => {
  const history = useHistory();
  const { data: packsData, loading: loadingPacks } = useListPacks();
  const { data: entranceTicketTypesData, loading: loadingEntranceTicketTypes } =
    useListEntranceTicketTypesQuery();

  const [entranceTicketTypes, setEntranceTicketTypes] = useState<EntranceTicketType[]>(
    []
  );

  const sortEntranceTicketTypesForPurchaseByIsBicycle = () => {
    const isBicycleTickets = entranceTicketTypesData?.listEntranceTicketTypes.filter(
      (ticket) => ticket.isBicycle
    );

    const isNotBicycleTickets = entranceTicketTypesData?.listEntranceTicketTypes.filter(
      (ticket) => !ticket.isBicycle
    );

    return isNotBicycleTickets?.concat(isBicycleTickets || []) || [];
  };

  useEffect(() => {
    const sorted = sortEntranceTicketTypesForPurchaseByIsBicycle();
    setEntranceTicketTypes(sorted);
  }, [entranceTicketTypesData]);

  return (
    <div className={styles.Container}>
      <Row justify="center" align="middle">
        <Col span={24}>
          <h1 className={styles.Title}>{texts.title}</h1>
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <ActualParkAvailability />
      </Row>
      <LoadingModal visible={loadingPacks || loadingEntranceTicketTypes} />
      <h1 className={styles.SubTitle}>{texts.entrances}</h1>
      <div className={styles.ScrollEntrances}>
        {packsData?.listPacks.map((pack) => (
          <>
            {pack.visibility && (
              <Col
                className={styles.Column}
                xs={16}
                sm={16}
                md={12}
                lg={12}
                xl={8}
                xxl={8}
                key={pack.id}
              >
                <EntranceCard item={pack} isPack images={pack.packImages} />
              </Col>
            )}
          </>
        ))}
        {entranceTicketTypes.map((entranceTicketType) => (
          <Col
            className={styles.Column}
            xs={16}
            sm={16}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
            key={entranceTicketType.id}
          >
            <EntranceCard
              item={entranceTicketType}
              isPack={false}
              images={entranceTicketType.entranceImages || []}
            />
          </Col>
        ))}
      </div>
      <h1 className={styles.SubTitle}>{texts.includeToYourEntrance}</h1>
      <div className={styles.ScrollEntrances}>
        <Col className={styles.Column} xs={16} sm={16} md={12} lg={12} xl={8} xxl={8}>
          <GrillCard />
        </Col>
      </div>
      <Row justify="center" align="bottom" className={styles.ButtonRow}>
        <Col xs={16} sm={16} md={12} lg={12} xl={8} xxl={8}>
          <Button
            className={styles.PayButton}
            shape="round"
            onClick={() => history.push('date')}
          >
            {texts.pay}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SchedulesAndPrices;
