import { gql, useMutation } from '@apollo/client';
import {
  CorporateEventContactOutput,
  MutationSendTricaoClassroomEmailArgs,
} from 'apollo/types';

const SEND_EMAIL = gql`
  mutation sendTricaoClassroomEmail($input: CorporateEventContactInput!) {
    sendTricaoClassroomEmail(input: $input) {
      response
    }
  }
`;

const useSendTricaoClassroomEmail = () => {
  return useMutation<
    { sendTricaoClassroomEmail: CorporateEventContactOutput },
    MutationSendTricaoClassroomEmailArgs
  >(SEND_EMAIL, {});
};

export default useSendTricaoClassroomEmail;
