import React, { FC, useEffect, useState } from 'react';
import { Carousel, Col, Row } from 'antd';
import Binoculars from 'assets/images/activityIcon.png';
import { ThingToDo } from 'apollo/types';
import useMaxBreakpoint from 'utils/styles/useMaxBreakpoint';
import { Breakpoints } from 'utils/styles/breakpoints';
import * as texts from 'assets/texts/activities';
import ActivityDetails from './ActivityDetails';
import styles from './Activities.module.scss';
import ActivityButton from './ActivityButton';

interface PlaceProps {
  thingsToDo: ThingToDo[];
}

const ThingsToDo: FC<PlaceProps> = (props: PlaceProps) => {
  const { thingsToDo } = props;
  const [selectedThingToDoId, setSelectedThingToDoId] = useState<number>();
  const [selectedThingToDo, setSelectedThingToDo] = useState<ThingToDo>();
  const [slidesToShow, setSlidesToShow] = useState<number>(3);
  const currentBreakpoint = useMaxBreakpoint();

  const setSlideToShow = () => {
    if (currentBreakpoint <= Breakpoints.XS) {
      setSlidesToShow(2);
    } else if (currentBreakpoint <= Breakpoints.SM) {
      setSlidesToShow(3);
    } else if (currentBreakpoint <= Breakpoints.MD) {
      setSlidesToShow(4);
    } else if (currentBreakpoint <= Breakpoints.LG) {
      setSlidesToShow(5);
    } else {
      setSlidesToShow(7);
    }
  };

  useEffect(() => {
    if (selectedThingToDoId !== undefined) {
      setSelectedThingToDo(thingsToDo[selectedThingToDoId]);
    }
  }, [selectedThingToDoId]);

  useEffect(() => {
    setSlideToShow();
  }, [currentBreakpoint]);

  return (
    <div className={styles.Container}>
      <Row justify="center">
        <Col span={24}>
          <h2>{texts.thingsTodoHomeTitle}</h2>
        </Col>
      </Row>
      <div className={styles.ScrollableList}>
        <Carousel
          dots={false}
          slidesToShow={slidesToShow}
          draggable
          swipeToSlide
          touchThreshold={50}
          focusOnSelect
          infinite
          autoplay
        >
          {thingsToDo?.map((thingToDo, index) => {
            const thingsToDoIcon = thingToDo.icon as string | undefined;
            const coverImageUrl = thingToDo.thingToDoImages
              ? thingToDo.thingToDoImages[0].image
              : '';
            return (
              <ActivityButton
                key={thingToDo.id}
                icon={thingsToDoIcon || Binoculars}
                name={thingToDo.name}
                coverImageUrl={coverImageUrl as string}
                id={index}
                setSelectedThingToDoId={setSelectedThingToDoId}
              />
            );
          })}
        </Carousel>
      </div>
      <div>{selectedThingToDo && <ActivityDetails thingToDo={selectedThingToDo} />}</div>
    </div>
  );
};

export default ThingsToDo;
