export const programs = 'PROGRAMAS';
export const quote = 'Cotizar';
export const estimatedDuration = 'Duración aprox.';
export const hours = 'horas';
export const age = 'Edad';
export const imageGallery = 'Galería de imágenes';
export const inscriptionForm = 'Formulario de inscripción';
export const name = 'Nombre';
export const surname = 'Apellido';
export const email = 'Email';
export const emailError = 'Email inválido';
export const sendEmailError = 'Error al enviar el email';
export const sendEmailSuccess = 'Email enviado correctamente';
export const selectDate = 'Fecha tentativa de visita';
export const phone = 'Teléfono';
export const comments = 'Comentarios';
export const send = 'Enviar';
export const otherPrograms = 'Otros programas que te podrían interesar';
export const youtubeLink = 'Video presentación';
export const quoteTitle =
  '¡Aula Tricao es, sin duda, una oportunidad única de aprender en contacto con nuestra linda naturaleza!';
export const quoteImage =
  'https://tricao-production.s3.us-west-2.amazonaws.com/assets/carousel/FOTO_1.jpg';
