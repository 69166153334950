import React, { ReactElement } from 'react';
import DirectorWords from 'components/WeAre/DirectorWords';
import SquareInformation from 'components/WeAre/SquareInformation';
import History from 'components/WeAre/History';
import Header from 'components/WeAre/Header';
import MissionAndVision from 'components/WeAre/MissionAndVision';
// import OurTeam from 'components/WeAre/OurTeam';
import style from './WeAre.module.scss';

const WeAre = (): ReactElement => {
  return (
    <div className={style.Container}>
      <Header />
      <MissionAndVision />
      <DirectorWords />
      <SquareInformation />
      {/* <OurTeam /> */}
      <History />
    </div>
  );
};

export default WeAre;
