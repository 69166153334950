import React, { ReactElement } from 'react';
import { historyTitle, historyText, yearsDescriptions } from 'assets/texts/weAre';
import { Col, Row } from 'antd';
import styles from './History.module.scss';
import Timeline from './Timeline';

const History = (): ReactElement => {
  return (
    <div className={styles.Container}>
      <Row justify="center">
        <Col span={24}>
          <h2 className={styles.Title}>{historyTitle}</h2>
          <p>{historyText}</p>
        </Col>
      </Row>
      <Timeline yearsDescriptions={yearsDescriptions} />
    </div>
  );
};

export default History;
