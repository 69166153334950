import React, { FC, useEffect, useState } from 'react';
import useGetPlaceQuery from 'apollo/resolvers/places/get';
import { useParams } from 'react-router-dom';
import Places from 'components/sharedComponents/Places';
import { Place, ThingToDo } from 'apollo/types';
import useListGrillsQuery from 'apollo/resolvers/grills/list';
import ListGrills from 'components/Grills/ListGrills';
import { Header } from 'components/Place/Header';
import LoadingModal from 'components/generalUI/LoadingModal';
import { Description } from 'components/Place/Description';
import styles from './Place.module.scss';

const PlaceComponent: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetPlaceQuery(id);
  const { data: grillsData, loading: grillLoading } = useListGrillsQuery();
  const [place, setPlace] = useState<Place | null>(null);
  const [thingsToDo, setThingsToDo] = useState<ThingToDo[] | null>(null);
  const [grills, setGrills] = useState<Place[]>();

  const getGrills = () => {
    if (grillsData) {
      setGrills(grillsData.listGrills);
    }
  };

  const renderGrills = () => {
    if (place?.name === 'Quinchos' && grills) {
      return <ListGrills grills={grills} />;
    }
    return <></>;
  };

  const getPlace = () => {
    if (data && data.getPlace) {
      setPlace(data.getPlace);
    }
  };

  useEffect(() => {
    getPlace();
  }, [data]);

  useEffect(() => {
    getGrills();
  }, [grillsData]);

  useEffect(() => {
    if (place && place.thingsToDo && place.thingsToDo.length > 0) {
      setThingsToDo(place.thingsToDo as ThingToDo[]);
    }
  }, [place]);

  return (
    <>
      <LoadingModal visible={loading || grillLoading} />
      <div className={styles.Container}>
        {place && (
          <div className={styles.PlaceContainer}>
            <Header name={place.name} coverImage={place.placeMainImage as string} />
            <Description
              description={place.mainDescription}
              thingsToDo={thingsToDo || []}
              placeImages={
                place.placeImages && place.placeImages.length > 1
                  ? place.placeImages.map((image) => image.image as string)
                  : []
              }
            />
          </div>
        )}
        {renderGrills()}
        <Places />
      </div>
    </>
  );
};

export default PlaceComponent;
