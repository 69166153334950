import { Col, Row } from 'antd';
import React, { FC } from 'react';
import Button from 'components/generalUI/Button';
import * as texts from '../../../assets/texts/program';
import styles from './Header.module.scss';

export interface HeaderProps {
  title: string;
  subtitle: string;
  coverImage: string;
}

const Header: FC<HeaderProps> = ({ title, subtitle, coverImage }: HeaderProps) => {
  return (
    <Row className={styles.Container}>
      <Col className={styles.MainTextContainer} xs={24} sm={8}>
        <h3 className={styles.UpperText}>{texts.programs}</h3>
        <h1 className={styles.Title}>{title}</h1>
        <p className={styles.Subtitle}>{subtitle}</p>
        <Button className={styles.QuoteButton}>
          <a href="#quote-program">{texts.quote}</a>
        </Button>
      </Col>
      <Col className={styles.MainImageContainer} xs={24} sm={16}>
        <img className={styles.MainImage} src={coverImage} alt="Program cover" />
      </Col>
    </Row>
  );
};

export default Header;
