/* eslint-disable react/no-danger */
import { Carousel, Col, Row } from 'antd';
import React, { FC } from 'react';
import * as texts from 'assets/texts/places';
import { ThingToDo } from 'apollo/types';
import ScrollableItem from 'components/sharedComponents/ScrollableItem';
import styles from './Description.module.scss';

export interface DescriptionProps {
  description: string;
  thingsToDo: ThingToDo[];
  placeImages: string[];
}

const MAXIMUM_IMAGES_TO_SHOW = 5;
const MINIMUM_IMAGES_TO_SHOW = 2;

export const Description: FC<DescriptionProps> = ({
  description,
  thingsToDo,
  placeImages,
}: DescriptionProps) => {
  const { innerWidth: width } = window;

  const backgroundColorClassName: Record<string, string> = {
    Picnic: styles.YellowContainer,
    Aves: styles.YellowContainer,
    'Kayaks y botes': styles.BlueContainer,
    'Pump track': styles.BrownContainer,
    Flora: styles.GreenContainer,
    'Stand-up paddle': styles.BlueContainer,
    Senderismo: styles.BrownContainer,
    'Circuito MTB': styles.BrownContainer,
    Balsa: styles.BlueContainer,
    Fotografía: styles.YellowContainer,
    'Muro de escalada': styles.BrownContainer,
    Bowlpark: styles.BrownContainer,
  };

  const getImagesToShow = () => {
    if (placeImages.length < MAXIMUM_IMAGES_TO_SHOW) {
      return placeImages.length;
    }

    if (width > 768) {
      return MAXIMUM_IMAGES_TO_SHOW;
    }

    return MINIMUM_IMAGES_TO_SHOW;
  };

  return (
    <>
      <Row gutter={[20, 20]} justify="space-between" className={styles.Container}>
        <Col span={24}>
          <h2 className={styles.Title}>{texts.whatToDo}</h2>
        </Col>
        <Col span={24}>
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </Col>
        <Col span={24}>
          <Row gutter={[16, 20]} justify="center">
            {thingsToDo.map((thingToDo) => {
              const nameHasBackgroundColor = Object.keys(
                backgroundColorClassName
              ).includes(thingToDo.name);
              return (
                <Col key={thingToDo.id}>
                  <div
                    className={
                      nameHasBackgroundColor
                        ? backgroundColorClassName[thingToDo.name]
                        : styles.GreenContainer
                    }
                    dangerouslySetInnerHTML={{ __html: thingToDo.icon || '' }}
                  />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className={styles.ScrollableList}>
            <Carousel
              dots={false}
              slidesToShow={getImagesToShow()}
              draggable
              swipeToSlide
              touchThreshold={50}
              focusOnSelect
              autoplay
            >
              {placeImages.map((imageUrl, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={`div-${index}`}>
                    <ScrollableItem
                      index={index}
                      imageUrl={imageUrl}
                      scrollableImageClassName={styles.ScrollableImage}
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
        </Col>
      </Row>
    </>
  );
};
