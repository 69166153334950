import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import LoadingModal from 'components/generalUI/LoadingModal';
import useListPacksForPurchaseQuery from 'apollo/resolvers/packs/listForPurchase';
import useListEntranceTicketTypesQuery from 'apollo/resolvers/entranceTicketTypes/listForPurchase';
import SelectCard from 'components/SelectEntrances/SelectCard';
import * as texts from 'assets/texts/selectEntrances';
import PurchaseSteps from 'components/generalUI/PurchaseSteps';
import getDateSearchParam from 'utils/dates/getDateSearchParam';
import { PackOrEntrancePurchase } from 'storage/types';
import { getOrCreatePurchaseData } from 'storage/utils/getOrCreatePurchaseData';
import BottomSummary from 'components/sharedComponents/BottomSummary';
import Button from 'components/generalUI/Button';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ActualParkAvailability from 'components/sharedComponents/ActualParkAvailability';
import isDateToDisplayIsBefore from 'utils/helpers/isDateToDisplayIsBefore';
import styles from './SelectEntrances.module.scss';

const SelectEntrances: FC = () => {
  const stringDate = getDateSearchParam();
  const history = useHistory();
  const url = `/summary?date=${stringDate}`;
  const date = new Date(stringDate);
  const displayDate = isDateToDisplayIsBefore(stringDate, date);

  const { data: packsData, loading: loadingPacks } =
    useListPacksForPurchaseQuery(stringDate);
  const { data: entranceTicketTypesData, loading: loadingEntranceTicketTypes } =
    useListEntranceTicketTypesQuery(stringDate);

  const buttonOnClick = () => {
    history.push(url);
  };

  const purchase = getOrCreatePurchaseData(stringDate);
  const { totalPrice } = purchase;

  const { packs, entrances } = getOrCreatePurchaseData(stringDate);

  const findInPurchase = (id: string, items: (PackOrEntrancePurchase | null)[]) => {
    const itemDetail = items.find((item) => item?.id === id);
    return itemDetail ? itemDetail.adultTicketCount + itemDetail.otherTicketCount : 0;
  };

  return (
    <div className={styles.Container}>
      <PurchaseSteps total={4} current={1} />
      <Row justify="center" align="bottom">
        <Col span={24}>
          <h1 className={styles.Title}>{texts.title}</h1>
        </Col>
      </Row>
      <Row align="middle" className={styles.DateRow}>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <div className={styles.DateButtonCol}>
            <Button onClick={() => history.push('/date')}>
              <ArrowLeftOutlined />
              {texts.selectAnotherDate}
            </Button>
          </div>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <h3 className={styles.Date}>{displayDate}</h3>
        </Col>
      </Row>
      <LoadingModal visible={loadingPacks || loadingEntranceTicketTypes} />
      <div className={styles.ScrollEntrances}>
        {packsData?.listPacksForPurchase.map((pack) => (
          <Col
            className={styles.Column}
            xs={16}
            sm={16}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
            key={pack.id}
          >
            <SelectCard
              item={pack}
              isPack
              images={pack.packImages}
              purchaseTickets={findInPurchase(pack.id, packs)}
              date={stringDate}
            />
          </Col>
        ))}
        {entranceTicketTypesData?.listEntranceTicketTypesForPurchase.map(
          (entranceTicketType) => (
            <Col
              className={styles.Column}
              xs={16}
              sm={16}
              md={12}
              lg={12}
              xl={8}
              xxl={8}
              key={entranceTicketType.id}
            >
              <SelectCard
                item={entranceTicketType}
                isPack={false}
                images={entranceTicketType.entranceImages || []}
                purchaseTickets={findInPurchase(entranceTicketType.id, entrances)}
                date={stringDate}
              />
            </Col>
          )
        )}
      </div>
      {(packs || entrances) && totalPrice ? (
        <BottomSummary
          total={totalPrice}
          totalMessage={texts.total}
          buttonMessage={texts.pay}
          buttonOnClick={buttonOnClick}
          isAffix
        />
      ) : (
        <div className={styles.Spacer} />
      )}
      <ActualParkAvailability date={stringDate} />
    </div>
  );
};

export default SelectEntrances;
