import React, { FC, useEffect, useState } from 'react';
import { Row, Col, Carousel } from 'antd';
import * as texts from 'assets/texts/grills';
import { Place } from 'apollo/types';
import useMaxBreakpoint from 'utils/styles/useMaxBreakpoint';
import { Breakpoints } from 'utils/styles/breakpoints';
import styles from './ListGrills.module.scss';
import ListGrillDetail from './ListGrillDetail';
import GrillButton from './GrillButton';

interface ListGrillsProps {
  grills: Place[];
}

const ListGrills: FC<ListGrillsProps> = (props: ListGrillsProps) => {
  const { grills } = props;
  const maxBreakpoint = useMaxBreakpoint();
  const [selectedGrill, setSelectedGrill] = useState<Place>();

  const getSlidesToShow = () => {
    if (maxBreakpoint <= Breakpoints.SM) {
      return grills.length > 2 ? 2 : grills.length;
    }
    if (maxBreakpoint <= Breakpoints.MD) {
      return grills.length > 4 ? 4 : grills.length;
    }
    if (maxBreakpoint <= Breakpoints.LG) {
      return grills.length > 5 ? 5 : grills.length;
    }
    if (maxBreakpoint <= Breakpoints.XL) {
      return grills.length > 6 ? 6 : grills.length;
    }

    return grills.length > 8 ? 8 : grills.length;
  };

  const setFirstGrill = () => {
    if (grills.length > 0) {
      setSelectedGrill(grills[0]);
    }
  };

  useEffect(() => {
    setFirstGrill();
  }, [grills]);

  return (
    <div className={styles.Container}>
      <Row justify="center">
        <Col lg={15} sm={16} xs={18}>
          <h2 className={styles.ListGrillsTitle}>{texts.grillListTitle}</h2>
        </Col>
      </Row>
      <div className={styles.ScrollableList}>
        <Carousel
          dots={false}
          slidesToShow={getSlidesToShow()}
          draggable
          swipeToSlide
          touchThreshold={50}
          focusOnSelect
          infinite
          autoplay
        >
          {grills?.map((grill) => {
            return (
              <GrillButton
                key={grill.id}
                place={grill}
                setSelectedGrill={setSelectedGrill}
              />
            );
          })}
        </Carousel>
      </div>
      {selectedGrill && <ListGrillDetail grill={selectedGrill} />}
    </div>
  );
};

export default ListGrills;
