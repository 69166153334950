import React from 'react';
import { Col, Row } from 'antd';
import Header from 'components/CorporateEvents/Header';
import TeamBuilding from 'components/CorporateEvents/TeamBuilding';
import OfficeTrips from 'components/CorporateEvents/OfficeTrips';
import useListThingsToDoQuery from 'apollo/resolvers/thingsToDo/list';
import ThingsToDo from 'components/sharedComponents/Activities';
import LoadingModal from 'components/generalUI/LoadingModal';
import styles from './CorporateEvents.module.scss';

const CorporateEvents = () => {
  const { data: thingsToDoData, loading: thingsToDoLoading } = useListThingsToDoQuery();
  return (
    <Row className={styles.Container}>
      <Col span={24}>
        <LoadingModal visible={thingsToDoLoading} />
        <Header />
        <TeamBuilding />
        <div className={styles.ThingsToDoContainer}>
          {thingsToDoData && <ThingsToDo thingsToDo={thingsToDoData.listThingsToDo} />}
        </div>
        <div id="quote-corporate-event">
          <OfficeTrips />
        </div>
      </Col>
    </Row>
  );
};

export default CorporateEvents;
