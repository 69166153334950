import React, { ReactElement } from 'react';
import { Col, Row } from 'antd';
import { squareInformation } from 'assets/texts/weAre';
import parse from 'html-react-parser';
import styles from './SquareInformation.module.scss';

const SquareInformation = (): ReactElement => {
  const getTitleClassName: Record<string, string> = {
    Educación: styles.Yellow,
    Conservación: styles.Green,
    Turismo: styles.Blue,
  };

  const getIconContainerClassName: Record<string, string> = {
    Educación: styles.YellowIconContainer,
    Conservación: styles.GreenIconContainer,
    Turismo: styles.BlueIconContainer,
  };
  return (
    <Row gutter={[40, { xs: 20 }]} justify="space-between" className={styles.Container}>
      {squareInformation.map(({ title, icon, text }) => {
        return (
          <Col md={24} lg={8} key={title}>
            <Row justify="start" gutter={[0, 16]}>
              <Col span={24}>
                <Row gutter={[8, 0]} align="middle">
                  <Col>
                    <div className={getIconContainerClassName[title]}>
                      <img src={icon} alt={title} className={styles.Icon} />
                    </div>
                  </Col>
                  <Col>
                    <h2 className={getTitleClassName[title]}>{title}</h2>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <p className={styles.Text}>{parse(text)}</p>
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

export default SquareInformation;
