import { Col, Row } from 'antd';
import Header from 'components/Program/Header';
import { useProgramViewModel } from 'components/Program/program.viewModel';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useGetProgramQuery from 'apollo/resolvers/programs/get';
import LoadingModal from 'components/generalUI/LoadingModal';
import { Program as ProgramType } from 'apollo/types';
import Description from 'components/Program/Description';
import Quote from 'components/Program/Quote';

const Program: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { setProgramFromQuery } = useProgramViewModel();
  const { data, loading } = useGetProgramQuery(id);

  const [program, setProgram] = useState<ProgramType>();

  useEffect(() => {
    setProgramFromQuery(data, setProgram);
  }, [data]);
  return (
    <Row>
      <Col span={24}>
        <LoadingModal visible={loading} />
        {program && (
          <>
            <Header
              title={program.title}
              subtitle={program.subtitle}
              coverImage={program.coverImage?.url || ''}
            />
            <Description
              title={program.title}
              description={program.description}
              duration={program.hoursDuration}
              targetGroup={program.targetGroup}
              programPictureUrls={
                program.secondaryImages?.map((image) => image.url) || []
              }
            />
            <div id="quote-program">
              <Quote />
            </div>
          </>
        )}
      </Col>
    </Row>
  );
};

export default Program;
