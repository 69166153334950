/* eslint-disable react/no-danger */
import React from 'react';
import { Col, Row } from 'antd';
import styles from './ActivityButton.module.scss';

interface ActivityButtonProps {
  id: number;
  icon: string;
  name: string;
  coverImageUrl: string;
  setSelectedThingToDoId: (id: number) => void;
}

const ActivityButton = ({
  icon,
  name,
  coverImageUrl,
  id,
  setSelectedThingToDoId,
}: ActivityButtonProps) => {
  const gradientClassName: Record<string, string> = {
    Picnic: styles.YellowGradient,
    Aves: styles.YellowGradient,
    'Kayaks y botes': styles.BlueGradient,
    'Pump track': styles.BrownGradient,
    Flora: styles.GreenGradient,
    'Stand-up paddle': styles.BlueGradient,
    Senderismo: styles.BrownGradient,
    'Circuito MTB': styles.BrownGradient,
    Balsa: styles.BlueGradient,
    Fotografía: styles.YellowGradient,
    'Muro de escalada': styles.BrownGradient,
    Bowlpark: styles.BrownGradient,
  };

  const nameHasGradient = Object.keys(gradientClassName).includes(name);

  return (
    <Row className={styles.Container} onClick={() => setSelectedThingToDoId(id)}>
      <div className={nameHasGradient ? gradientClassName[name] : styles.GreenGradient}>
        <Col className={styles.TextContainer} span={24}>
          <Row align="middle" justify="start">
            <Col span={6}>
              <div dangerouslySetInnerHTML={{ __html: icon }} className={styles.Icon} />
            </Col>
            <Col className={styles.TitleContainer} span={18}>
              <h6 className={styles.Title}>{name}</h6>
            </Col>
          </Row>
        </Col>
      </div>
      <Col className={styles.ImageContainer} span={24}>
        <img className={styles.MainImage} src={coverImageUrl} alt={name} />
      </Col>
    </Row>
  );
};

export default ActivityButton;
