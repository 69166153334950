import { Carousel, Col, Row } from 'antd';
import { Place } from 'apollo/types';
import React, { FC } from 'react';
import ScrollableItem from 'components/sharedComponents/ScrollableItem';
import styles from './ListGrillDetail.module.scss';

interface Props {
  grill: Place;
}

const ListGrillDetail: FC<Props> = ({ grill }: Props) => {
  const { innerWidth: width } = window;
  const imagesToShow =
    grill.placeImages && grill.placeImages.length > 0
      ? grill.placeImages.map((image) => image.image)
      : [];

  return (
    <Row
      gutter={[40, 0]}
      className={styles.Container}
      align="middle"
      justify="space-around"
    >
      <Col xs={24} sm={8}>
        <Row justify="start" align="middle">
          <h2 className={styles.Title}>{grill.name}</h2>
        </Row>
        <p
          className={styles.Description}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: grill.mainDescription }}
        />
      </Col>
      <Col className={styles.ImagesContainer} xs={24} sm={16}>
        <div className={styles.ScrollableList}>
          <Carousel
            slidesToShow={width > 768 ? 2 : 1}
            draggable
            swipeToSlide
            touchThreshold={50}
            dots={false}
            focusOnSelect
            autoplay
          >
            {imagesToShow.map((image) => {
              const url = image as string;
              return (
                <ScrollableItem
                  scrollableImageClassName={styles.ScrollableImage}
                  key={image}
                  imageUrl={url}
                  isForGrill
                />
              );
            })}
          </Carousel>
        </div>
      </Col>
    </Row>
  );
};

export default ListGrillDetail;
