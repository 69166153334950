import React, { FC } from 'react';
import { Row, Col } from 'antd';
import {
  directorWordsTitle,
  directorWordsText,
  directorWordsName,
  directorWordsCharge,
} from 'assets/texts/weAre';
import directorPhoto from 'assets/images/director-photo.png';
import parse from 'html-react-parser';
import styles from './DirectorWords.module.scss';

const DirectorWords: FC = () => {
  return (
    <div className={styles.Container}>
      <Row>
        <Col span={24} className={styles.TitleContainer}>
          <h2 className={styles.Title}>{directorWordsTitle}</h2>
        </Col>
      </Row>
      <Row justify="space-between" align="middle" className={styles.DirectorContainer}>
        <Col md={24} lg={{ span: 16, order: 2 }}>
          <Row gutter={[0, 10]} className={styles.DirectorWords} justify="start">
            <Col span={24}>
              <p>{parse(directorWordsText)}</p>
            </Col>
            <Col span={24}>
              <h4>{directorWordsName}</h4>
            </Col>
            <Col span={24}>
              <p>{directorWordsCharge}</p>
            </Col>
          </Row>
        </Col>
        <Col sm={24} md={12} lg={{ span: 6, order: 1 }}>
          <img src={directorPhoto} alt="directorPhoto" className={styles.DirectorPhoto} />
        </Col>
      </Row>
    </div>
  );
};

export default DirectorWords;
