import { Col, Row } from 'antd';
import Mission from 'assets/images/mission.png';
import Vision from 'assets/images/vision.png';
import React from 'react';
import * as texts from 'assets/texts/weAre';
import styles from './MissionAndVision.module.scss';

const MissionAndVision = () => {
  return (
    <Row gutter={[0, 40]} justify="space-between" className={styles.Container}>
      <Col
        md={24}
        lg={11}
        className={styles.InformationContainerLeft}
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(28, 25, 23, 0) 0%, #1c1917 100%), url(${Mission})`,
        }}
      >
        <Row className={styles.TextContainer} align="bottom">
          <div>
            <h3 className={styles.Text}>{texts.mission}</h3>
            <p className={styles.Text}>{texts.missionDescription}</p>
          </div>
        </Row>
      </Col>
      <Col
        md={24}
        lg={11}
        className={styles.InformationContainerRight}
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(28, 25, 23, 0) 0%, #1c1917 100%), url(${Vision})`,
        }}
      >
        <Row className={styles.TextContainer} align="bottom">
          <div>
            <h3 className={styles.Text}>{texts.vision}</h3>
            <p className={styles.Text}>{texts.visionDescription}</p>
          </div>
        </Row>
      </Col>
    </Row>
  );
};

export default MissionAndVision;
