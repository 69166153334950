import { Col, Row } from 'antd';
import React, { FC } from 'react';
import * as texts from 'assets/texts/places';
import styles from './Header.module.scss';

export interface HeaderProps {
  name: string;
  coverImage: string;
}

export const Header: FC<HeaderProps> = ({ name, coverImage }: HeaderProps) => {
  return (
    <Row className={styles.Container}>
      <Col className={styles.MainTextContainer} span={24}>
        <h3 className={styles.UpperText}>{texts.places}</h3>
        <h1 className={styles.Title}>{name}</h1>
      </Col>
      <Col className={styles.MainImageContainer} span={24}>
        <img className={styles.MainImage} src={coverImage} alt={name} />
      </Col>
    </Row>
  );
};
