import { Col, Row } from 'antd';
import React from 'react';
import * as texts from 'assets/texts/weAre';
import Image from 'assets/images/we-are.jpg';
import styles from './Header.module.scss';

const Header = () => {
  return (
    <Row className={styles.Container}>
      <Col className={styles.MainTextContainer} sm={24} md={12} lg={8}>
        <h3 className={styles.UpperText}>{texts.title}</h3>
        <h1 className={styles.Title}>{texts.tricao}</h1>
        <p className={styles.Description}>{texts.description}</p>
      </Col>
      <Col className={styles.MainImageContainer} sm={24} md={12} lg={16}>
        <img className={styles.MainImage} src={Image} alt="Quines somos" />
      </Col>
    </Row>
  );
};

export default Header;
