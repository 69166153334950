import React, { FC, useState, useEffect } from 'react';
import { Row, Col, Form, notification } from 'antd';
import { GiftCardWithRemainingAmount, PromotionCode } from 'apollo/types';
import priceFormat from 'utils/formats/priceFormat';
import SummaryCard from 'components/PurchaseSummary/SummaryCard';
import ClientForm from 'components/PurchaseSummary/ClientForm';
import PromotionCodeInput from 'components/PurchaseSummary/PromotionCodeInput';
import { priceWithDiscount, grillTotalPrice } from 'utils/helpers/discountValue';
import PurchaseSteps from 'components/generalUI/PurchaseSteps';
import BottomSummary from 'components/sharedComponents/BottomSummary';
import * as texts from 'assets/texts/purchaseSummary';
import getDateSearchParam from 'utils/dates/getDateSearchParam';
import { getOrCreatePurchaseData } from 'storage/utils/getOrCreatePurchaseData';
import useGetNewsBannerQuery from 'apollo/resolvers/newsBanner/get';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Button from 'components/generalUI/Button';
import { useHistory } from 'react-router-dom';
import ActualParkAvailability from 'components/sharedComponents/ActualParkAvailability';
import GiftCardInput from 'components/PurchaseSummary/GiftCardInput';
import getGiftCardSearchParams from 'utils/dates/getGiftCardSearchParams';
import captureEvent from 'utils/post-hog';
import * as events from 'utils/post-hog/events';
import isDateToDisplayIsBefore from 'utils/helpers/isDateToDisplayIsBefore';
import styles from './PurchaseSummary.module.scss';

const PurchaseSummary: FC = () => {
  const date = getDateSearchParam();
  const history = useHistory();
  const sellUrl = `/sell?date=${date}`;

  const giftCardParams = getGiftCardSearchParams();

  const { packs, entrances, grill, totalPrice } = getOrCreatePurchaseData(date);

  const [form] = Form.useForm();
  const displayDate = isDateToDisplayIsBefore(date, new Date(date));
  const [promotionCode, setPromotionCode] = useState<PromotionCode | null>(null);
  const [giftCard, setGiftCard] = useState<GiftCardWithRemainingAmount | null>(null);
  const [totalWithDiscount, setTotalWithDiscount] = useState<number>();

  const { data: newsBannerData } = useGetNewsBannerQuery();

  const setNewsBannerFromQuery = () => {
    if (newsBannerData) {
      if (newsBannerData.getNewsBanner.isVisible) {
        const { title, body } = newsBannerData.getNewsBanner;
        notification.open({
          top: 80,
          duration: 0,
          className: styles.Notification,
          message: (
            <div
              className={styles.NotificationContent}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: title }}
            />
          ),
          description: (
            <div
              className={styles.NotificationContent}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: body }}
            />
          ),
        });
      }
    }
  };

  const saveNewTotal = () => {
    if (promotionCode || giftCard) {
      const purchase = getOrCreatePurchaseData(date);
      purchase.totalPrice = totalWithDiscount as number;
      sessionStorage.setItem('purchase', JSON.stringify(purchase));
    }
  };

  const applyDiscount = () => {
    let newTotal = 0;
    newTotal += priceWithDiscount(entrances, promotionCode?.promotionCodeEntrances);
    newTotal += priceWithDiscount(packs, [], promotionCode?.promotionCodePacks);
    newTotal += grillTotalPrice(grill, promotionCode?.promotionCodeActivities);
    setTotalWithDiscount(newTotal);
  };

  const applyGiftCard = () => {
    if (giftCard) {
      const totalValueAfterGiftCard = Math.max(
        (totalWithDiscount || totalPrice) - giftCard.remainingAmount,
        0
      );
      setTotalWithDiscount(totalValueAfterGiftCard);
    }
  };
  const renderSummaryCards = () => {
    if (giftCardParams) {
      return <SummaryCard type="giftCard" items={[giftCardParams]} />;
    }
    return (
      <>
        {packs.length ? <SummaryCard type="pack" items={packs} /> : <></>}
        {entrances.length ? <SummaryCard type="entrance" items={entrances} /> : <></>}
        {grill ? <SummaryCard type="grill" items={[grill]} /> : <></>}
      </>
    );
  };

  const checkIfPurchaseHasOnlyGrillsAndNotPromotionCode = () => {
    if (entrances.length === 0 && packs.length === 0) {
      const not100PercentDiscount = promotionCode?.promotionCodeActivities.find(
        (activity) => activity.percentageDiscount !== 100
      );
      const noPromotionCodeOrNot100PercentDiscount =
        !promotionCode || not100PercentDiscount !== undefined;
      if (grill && noPromotionCodeOrNot100PercentDiscount) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (promotionCode) {
      applyDiscount();
      setGiftCard(null);
    }
  }, [promotionCode]);

  useEffect(() => {
    applyGiftCard();
  }, [giftCard]);

  useEffect(() => {
    setNewsBannerFromQuery();
  }, [newsBannerData]);

  const payPurchase = () => {
    saveNewTotal();
    form.submit();
    captureEvent(events.toPaymentServiceButtonClicked);
  };

  const isNotGiftCardPurchase = !giftCardParams && !giftCard;

  return (
    <div className={styles.Container}>
      <PurchaseSteps total={4} current={2} />
      <Row justify="center" align="bottom">
        <Col span={24}>
          <h1 className={styles.Title}>{texts.title}</h1>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={24}>
          <h3>{displayDate}</h3>
        </Col>
      </Row>
      {renderSummaryCards()}
      {!giftCardParams && (
        <div className={styles.AddAnotherEntrance}>
          <Button kind="secondary" onClick={() => history.push(sellUrl)}>
            <ArrowLeftOutlined />
            {texts.addAnotherEntrance}
          </Button>
          <p>{texts.orContinueBelow}</p>
        </div>
      )}
      {totalPrice >= 0 && (
        <>
          <ClientForm form={form} date={date} />
          {!promotionCode && isNotGiftCardPurchase && (
            <PromotionCodeInput setPromotionCode={setPromotionCode} date={date} />
          )}
          {isNotGiftCardPurchase && (
            <GiftCardInput setGiftCard={setGiftCard} date={date} />
          )}
          <BottomSummary
            total={Number(giftCardParams?.amount) || totalPrice}
            totalMessage={texts.total}
            buttonMessage={texts.pay}
            buttonOnClick={payPurchase}
            isAffix={false}
            isDisabled={checkIfPurchaseHasOnlyGrillsAndNotPromotionCode()}
            disabledMessage={texts.grillWithoutEntrance}
          />
          {totalWithDiscount !== undefined && (
            <>
              <Row>
                <Col xs={21} lg={16}>
                  <p className={styles.DiscountText}>
                    {texts.discount} -{priceFormat.format(totalPrice - totalWithDiscount)}
                  </p>
                </Col>
                <Col xs={21} lg={16}>
                  <h2 className={styles.TotalDetail}>
                    {texts.newTotal}
                    {priceFormat.format(totalWithDiscount)}
                  </h2>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
      <ActualParkAvailability date={date} />
    </div>
  );
};

export default PurchaseSummary;
