import React, { FC, useState } from 'react';
import { Col, Divider, Row } from 'antd';
import styles from './Timeline.module.scss';
import { Props } from './types';

const Timeline: FC<Props> = ({ yearsDescriptions }: Props) => {
  const [selectedDescriptionIndex, setSelectedDescriptionIndex] = useState(0);

  const descriptionsHeaders: { year: string; title: string }[] = yearsDescriptions.map(
    (description) => ({
      year: description.year,
      title: description.description.title,
    })
  );

  return (
    <Row gutter={[10, { xs: 10 }]} className={styles.Container}>
      <Col xs={24} className={styles.YearContainer} sm={6}>
        {descriptionsHeaders.map(({ year, title }, index) => (
          <Row
            gutter={[0, 10]}
            // eslint-disable-next-line react/no-array-index-key
            key={`${year}-${index}`}
            className={
              index === selectedDescriptionIndex
                ? styles.SelectedHeaderContainer
                : styles.HeaderContainer
            }
            onClick={() => setSelectedDescriptionIndex(index)}
          >
            <Col
              span={24}
              className={
                index === selectedDescriptionIndex ? styles.SelectedHeader : styles.Header
              }
            >
              <h4>{year}</h4>
              <p>{title}</p>
              <Divider className={styles.Divider} />
            </Col>
          </Row>
        ))}
      </Col>
      <Col xs={24} sm={18} className={styles.DescriptionContainer}>
        <Row>
          <Col span={24}>
            <div className={styles.ImageContainer}>
              <img
                className={styles.Image}
                src={yearsDescriptions[selectedDescriptionIndex].imageUrl}
                alt={yearsDescriptions[selectedDescriptionIndex].description.title}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={[0, 12]} className={styles.TextContainer} justify="start">
          <Col span={24}>
            <h5 className={styles.Text}>
              {yearsDescriptions[selectedDescriptionIndex].year}
            </h5>
          </Col>
          <Col span={24}>
            <h3 className={styles.Text}>
              {yearsDescriptions[selectedDescriptionIndex].description.title}
            </h3>
          </Col>
          <Col span={24}>
            <Divider className={styles.TimeLineDetailDivider} />
          </Col>
          <Col span={24}>
            <p className={styles.Text}>
              {yearsDescriptions[selectedDescriptionIndex].description.text}
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Timeline;
